import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import TwoItemsRow from '../mdx/two-items-row/TwoItemsRow'
import CustomImage from '../mdx/custom-image/CustomImage'
import LightboxImage from '../mdx/lightbox-image/LightboxImage';
import { MDXProvider } from '@mdx-js/react';
import SideBySideImages from '../mdx/side-by-side-images/SideBySideImages';
import RedirectToRegister from '../mdx/redirect-to-register/RedirectToRegister';

const MdxLayout = ({children}) => {
  return (
    <MDXProvider components={{ TwoItemsRow, CustomImage, LightboxImage, SideBySideImages, RedirectToRegister }}>
      <MDXRenderer>
        {children}
      </MDXRenderer>
    </MDXProvider>
  );
};

export default MdxLayout;
