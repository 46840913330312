import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const CustomImage = ({ src, alt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { allImageSharp } = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid(maxWidth: 1500) {
                            ...GatsbyImageSharpFluid
                            originalName
                        }
                    }
                }
            }
        }
    `);
  const image = allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === src
  );
  if (!image) {
    return null;
  }

  return (
    <div>
      <div onClick={() => setIsOpen(true)} className={"cursor-pointer"}>
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />
      </div>
      <div className={"z-50 w-full h-full"}>
        {isOpen && <Lightbox
            reactModalStyle={{zIndex: 99999}}
            mainSrc={image.node.fluid.src}
            enableZoom={false}
            onCloseRequest={() => setIsOpen(false)}
        />}
      </div>
    </div>
  );
}

export default CustomImage;