import React from 'react';

const TwoItemsRow = ({text}) =>  {
  return (<>
    <div className={"flex flex-row"}>
      <div>
        <p>{text}</p>
      </div>
      <div>
        <p>YO</p>
      </div>
    </div>
    </>
  );
};

export default TwoItemsRow;