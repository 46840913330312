import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import RelatedArticlesCarousel from '../../components/RelatedArticlesCarousel'
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import { hostname } from '../../utils/constants';
import MdxLayout from '../../components/layout/MdxLayout';

export default function BlogDetails({data, pageContext}) {
  const post = data.mdx;

  let ldJsonHome = "Home";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + "/blog/",
          "name": "Blog"
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": hostname + pageContext?.url,
          "name": pageContext?.name,
          "image": hostname + pageContext?.metaImage
        }
      },
    ]
  };

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages}
                lang={data?.mdx?.fields?.lang}
                canonical={data?.mdx?.fields?.canonical}
                title={post?.frontmatter?.title}
                seo={{
                  metaTitle: post?.frontmatter?.metaTitle,
                  metaDescription: post?.frontmatter?.metaDescription,
                  metaImage: post?.frontmatter?.metaImage?.publicURL,
                  metaCover: post?.frontmatter?.metaCover?.publicURL,
                  twitterCard: post?.frontmatter?.twitterCard,
                  ldJson: post?.frontmatter?.ldJson,
                  navigationLdJson: navigationLdJson,
                  keywords: post?.frontmatter?.metaKeywords,
                  slug: post?.fields?.slug.replace("/posts", "")}}>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96 "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5 capitalize"}><Link to={"/"}>Home</Link> > <Link to={"/blog/"}>Blog</Link> > {post?.frontmatter.path?.replaceAll('-', ' ')}</p>
        </div>
      </div>
      <div className={"flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  pt-10"}>
        <div className={"self-center blog-header-image-container"}>
        {post?.frontmatter?.cover?.childImageSharp?.gatsbyImageData && <GatsbyImage
          image={post?.frontmatter?.cover?.childImageSharp?.gatsbyImageData}
          alt={post?.frontmatter?.coverAlt}
          className={"self-center blog-header-image"} /> }
        </div>
        <div className={"p-5 blog-body w-full self-center"}>
          <h1 className={"text-primary-default relative font-semibold text-2xl text-center uppercase"}>{post?.frontmatter?.title}</h1>
          <small>{post?.frontmatter?.date}</small>
          <div className={"my-5"}>
            {post?.body && <MdxLayout>{post.body}</MdxLayout>}
          </div>
        </div>
      </div>
      <RelatedArticlesCarousel language={pageContext?.language} tags={post?.frontmatter?.categories} title={post.frontmatter.title} />
    </SiteLayout>
  );
}
